import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import { useIsIndexed } from './hooks/useIsIndexed';

export function MetaRobotsContainer(): JSX.Element | null {
	const indexed = useIsIndexed();

	if (indexed) return null;

	return (
		<Helmet>
			<meta name="robots" content="noindex" />
		</Helmet>
	);
}
