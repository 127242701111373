import type { JSX } from 'react';

import { Link } from '@change-corgi/design-system/components/actions';

import type { SimplifiedDecisionMaker } from 'src/app/pages/petition/details/shared/types';

import { DecisionMakerLabel } from './DecisionMakerLabel';

type Props = Readonly<{
	decisionMaker: SimplifiedDecisionMaker;
}>;

export function DecisionMakerLink({ decisionMaker }: Props): JSX.Element {
	return (
		<Link to={`/decision-makers/${decisionMaker.slug || decisionMaker.id}`}>
			<DecisionMakerLabel decisionMaker={decisionMaker} />
		</Link>
	);
}
