import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { CreateMediaHitMutation, CreateMediaHitMutationVariables } from './createMediaHit.graphql';

export async function createMediaHit(mediaLink: string, petitionId: string, utilityContext: UtilityContext) {
	const {
		gql: { fetch },
	} = utilityContext;

	const result = await fetch<CreateMediaHitMutation, CreateMediaHitMutationVariables>({
		query: gql`
			mutation CreateMediaHit($input: CreatePetitionCommentInput!) {
				createPetitionComment(input: $input) {
					success
					reason
					comment {
						id
						comment
					}
				}
			}
		`,
		variables: {
			input: {
				petitionId,
				comment: mediaLink,
				role: 'MEDIA_HIT_COMMENT',
			},
		},
	});

	return result.data?.createPetitionComment;
}
