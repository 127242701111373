import { type JSX, type PropsWithChildren } from 'react';

import { Separator } from '@change-corgi/design-system/components/content';
import { Box, Flex } from '@change-corgi/design-system/layout';

import type { DecisionMaker } from 'src/app/pages/petition/details/shared/types';

import { useContactDM } from '../../../hooks/decisionMakers/useContactDM';
import { useDmStatus } from '../../../hooks/decisionMakers/useDmStatus';
import type { AllDmResponses } from '../hooks/useDecisionMakers';

import { ContactDM } from './ContactDM';
import { DmStatus } from './DmStatus';

type Props = {
	photoContent: JSX.Element;
	decisionMaker: DecisionMaker;
	allDmResponses: AllDmResponses;
};

export function DecisionMakerListItem({
	photoContent,
	decisionMaker,
	allDmResponses,
	children,
}: PropsWithChildren<Props>): JSX.Element {
	const {
		data: { showDmStatus },
	} = useDmStatus(decisionMaker);

	const {
		data: { showContactCTA },
	} = useContactDM(decisionMaker);

	return (
		<>
			<Separator role="presentation" mt={0} mb={8} sx={{ color: 'neutral-grey100' }} />

			<Flex sx={{ alignItems: 'center' }} mb={20}>
				{photoContent}

				<Box sx={{ flexGrow: 1, overflow: 'hidden' }}>{children}</Box>
			</Flex>

			{showDmStatus && <DmStatus decisionMaker={decisionMaker} allDmResponses={allDmResponses} />}
			{showContactCTA && <ContactDM contactInfo={decisionMaker.contactInfo} displayName={decisionMaker.displayName} />}
		</>
	);
}
