import { useContext, useMemo } from 'react';

import { useGetBanditHeadlineText } from '../bandits';

import { ShareHeadlineContext } from './context';
import type { ShareHeadline } from './types';

type Result = Pick<ShareHeadline, 'title' | 'description'>;

export function useShareHeadline(): Result | undefined {
	const context = useContext(ShareHeadlineContext);
	const getBanditHeadlineText = useGetBanditHeadlineText(context?.sharer);

	return useMemo(() => {
		if (!context || !context?.sharer) return undefined;
		return {
			title: getBanditHeadlineText(context.title) || '',
			description: getBanditHeadlineText(context.description) || '',
		};
	}, [context, getBanditHeadlineText]);
}
