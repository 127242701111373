/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo } from 'react';

import type { Article } from 'schema-dts';

import { usePetitionDetailsPageData } from 'src/app/pages/petition/details/pageContext';
import { jsonLd } from 'src/app/shared/utils/jsonLd';

import { usePhoto } from '../../../../../hooks/photo';

type Result = ModelHookResult<{
	schemaJson: string;
}>;

export function useMetaArticleSchema(): Result {
	const {
		data: {
			petition: {
				ask,
				lastEditedAt,
				publishedAt,
				user: { displayName: userDisplayName, id: userId },
			},
		},
	} = usePetitionDetailsPageData();

	const photo = usePhoto();

	const articleSchema = useMemo(
		() =>
			jsonLd<Article>({
				'@context': 'https://schema.org',
				'@type': 'Article',
				headline: ask,
				...(photo && { image: [photo?.url] }),
				datePublished: publishedAt ?? undefined,
				...(lastEditedAt && { dateModified: lastEditedAt }),
				author: [
					{
						'@type': 'Person',
						name: userDisplayName,
						url: `https://www.change.org/u/${userId}`,
					},
				],
			}),
		[ask, lastEditedAt, photo, publishedAt, userDisplayName, userId],
	);

	return {
		data: {
			schemaJson: articleSchema,
		},
	};
}
