import { useMemo } from 'react';

import { useParams } from 'react-router';

import type { Sharer } from 'src/app/pages/petition/details/shared/types';

export function useRouteGuestSharer(): Sharer | undefined {
	const { sharerDisplayName, sharerLocation } = useParams<{ sharerDisplayName?: string; sharerLocation?: string }>();

	return useMemo(() => {
		if (!sharerDisplayName || !sharerLocation) return undefined;

		return {
			displayName: sharerDisplayName,
			shortDisplayName: sharerDisplayName,
			formattedLocationString: sharerLocation,
		};
	}, [sharerDisplayName, sharerLocation]);
}
