import type { JSX, PropsWithChildren } from 'react';

import { PageScrollPositionContext } from './context';
import { usePageScrollPositionState } from './pageScrollPositionState';

export function PageScrollPositionProvider({ children }: PropsWithChildren<EmptyProps>): JSX.Element {
	const info = usePageScrollPositionState();

	return <PageScrollPositionContext.Provider value={info}>{children}</PageScrollPositionContext.Provider>;
}
