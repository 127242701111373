import type { SignaturesCluster } from '../../shared/types';

export function mapParametersBuilder() {
	const mapParameters = (signaturesClusters: SignaturesCluster[], zoomLevel: number) => {
		const { center } = signaturesClusters[0];

		return {
			zoom: zoomLevel,
			center: { lat: center.latitude, lng: center.longitude },
			mapId: 'signatures_distribution_map',
			disableDefaultUI: true,
		};
	};

	return { mapParameters };
}
