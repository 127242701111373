import type { JSX } from 'react';

import { Image } from '@change-corgi/design-system/components/media';

type Props = Readonly<{
	id: string;
	url: string;
	mobileUrl: string;
}>;

export function Photo({ id, url, mobileUrl }: Props): JSX.Element {
	return (
		<Image
			fetchPriority="high"
			aspectRatio="16/9"
			fit="contain"
			width="100%"
			id={id}
			src={[mobileUrl, url]}
			alt=""
			sx={{
				borderRadius: 'standard',
				// removes an unwanted padding under the image
				verticalAlign: 'top',
			}}
		/>
	);
}
