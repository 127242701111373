/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';

import { usePetitionDetailsPageData } from 'src/app/pages/petition/details/pageContext';

import { SupporterVoicesComponent } from './Layout';

export function SupporterVoices(): React.JSX.Element | null {
	const {
		data: { supporterVoicesData },
	} = usePetitionDetailsPageData();

	return <SupporterVoicesComponent supporterVoicesData={supporterVoicesData} />;
}
