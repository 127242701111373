import type { PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';

import type { MediaHitsContextValue } from './context';
import { useMediaHitsContextValue } from './context';

export const MediaHitsContext = createContext<MediaHitsContextValue>(null as unknown as MediaHitsContextValue);

export function MediaHitsContextProvider({ children }: PropsWithChildren) {
	const value = useMediaHitsContextValue();

	return <MediaHitsContext.Provider value={value}>{children}</MediaHitsContext.Provider>;
}

export const useMediaHitsContext = () => {
	const value = useContext(MediaHitsContext);

	return value;
};
