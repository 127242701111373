import type { JSX, PropsWithChildren } from 'react';

import type { PetitionMitaAlternateImageData } from 'src/app/pages/petition/details/shared/types';

import { MitaContext } from './context';

type Props = { image: PetitionMitaAlternateImageData };

export function MitaProvider({ children, image }: PropsWithChildren<Props>): JSX.Element {
	return <MitaContext.Provider value={image}>{children}</MitaContext.Provider>;
}
