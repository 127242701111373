import type { ComponentPropsWithRef, JSX } from 'react';

import { Box } from '@change-corgi/design-system/layout';

import { AfterMediaWaypoint } from 'src/app/pages/petition/details/shared/waypoints';

import { usePetitionDetails } from '../../hooks/petitionDetails';
import { usePhoto } from '../../hooks/photo';
import { useFirstMovement } from '../../hooks/useFirstMovement';
import { MediaContainer } from '../Media';
import { MovementContainer } from '../Movement';

type Props = ComponentPropsWithRef<typeof Box>;

export function MediaAndMovement(props: Props): JSX.Element | null {
	const { id } = usePetitionDetails();
	const photo = usePhoto();
	const firstMovement = useFirstMovement();

	const hasMedia = !!photo;

	if (!hasMedia && !firstMovement) return null;

	return (
		<Box {...props} pt={[16, 8]} pb={[null, 8]}>
			{hasMedia && (
				<MediaContainer
					// using a key here to force a re-rendering of the media when switching petitions
					// otherwise we can have a slight delay where the media doesn't match the title
					key={id}
					photo={photo}
				/>
			)}
			<AfterMediaWaypoint />
			{firstMovement && (
				<Box mt={hasMedia ? 8 : 0}>
					<MovementContainer movement={firstMovement} alwaysExpanded={!hasMedia} />
				</Box>
			)}
		</Box>
	);
}
