import type { JSX } from 'react';

import { TranslateHtml } from '@change-corgi/core/react/i18n';
import { Text } from '@change-corgi/design-system/typography';

import type { PetitionDetails, SimplifiedDecisionMaker } from 'src/app/pages/petition/details/shared/types';

import { DecisionMakerLabel } from './DecisionMakerLabel';
import { DecisionMakerLink } from './DecisionMakerLink';
import { OtherDecisionMakersButton } from './OtherDecisionMakersButton';

type Props = Readonly<{
	decisionMakers: PetitionDetails['primaryDecisionMakerConnection'];
}>;

export function DecisionMakerContent({ decisionMakers }: Props): JSX.Element {
	const { totalCount, nodes } = decisionMakers;
	const firstDecisionMaker: SimplifiedDecisionMaker = nodes[0];

	if (totalCount === 1) {
		return firstDecisionMaker.publiclyVisible ? (
			<DecisionMakerLink decisionMaker={firstDecisionMaker} />
		) : (
			<Text sx={{ fontSize: 'inherit', lineHeight: 'inherit' }}>
				<DecisionMakerLabel decisionMaker={firstDecisionMaker} />
			</Text>
		);
	}

	return (
		<TranslateHtml
			value="fe.pages.petition_show.petition_show_byline_content.decision_maker_multiple"
			replacements={{
				primary_dm_link: firstDecisionMaker.publiclyVisible ? (
					<DecisionMakerLink decisionMaker={firstDecisionMaker} />
				) : (
					<Text sx={{ fontSize: 'inherit', lineHeight: 'inherit' }}>
						<DecisionMakerLabel decisionMaker={firstDecisionMaker} />
					</Text>
				),
				other_dms_link: <OtherDecisionMakersButton otherDecisionMakersCount={totalCount - 1} />,
			}}
		/>
	);
}
