import type { JSX } from 'react';

import { MetaArticleSchema } from './MetaArticleSchema';
import { MetaBreadcrumbSchema } from './MetaBreadcrumbSchema';
import { MetaDescriptionContainer } from './MetaDescription';
import { MetaHrefLangLink } from './MetaHrefLangLink';
import { MetaLocaleContainer } from './MetaLocale';
import { MetaPhotoContainer } from './MetaPhoto';
import { MetaRobotsContainer } from './MetaRobots';
import { MetaTypeContainer } from './MetaType';

export function MetaContainer(): JSX.Element | null {
	return (
		<>
			<MetaDescriptionContainer />
			<MetaTypeContainer />
			<MetaRobotsContainer />
			<MetaLocaleContainer />
			<MetaPhotoContainer />
			<MetaHrefLangLink />
			<MetaArticleSchema />
			<MetaBreadcrumbSchema />
		</>
	);
}
