import type { JSX } from 'react';

import type { Movement } from 'src/app/pages/petition/details/shared/types';

import { usePetitionDetails } from '../../hooks/petitionDetails/petitionDetails';

import { MovementCard } from './components/MovementCard';

type Props = {
	movement: Movement;
	alwaysExpanded?: boolean;
};

export function MovementContainer(props: Props): JSX.Element | null {
	const { id: petitionId } = usePetitionDetails();
	return <MovementCard petitionId={petitionId} {...props} />;
}
