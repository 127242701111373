import { useCallback } from 'react';

import { useSignaturesDistributionContext } from '../../../shared/provider';
import type { ClusterContributor, SignaturesCluster } from '../../../shared/types';
import { firstZipcode } from '../utils/firstZipcode';

export function useSaveComputedClusters() {
	const {
		actions: { setSignaturesClusters, setHasNoCluster },
	} = useSignaturesDistributionContext();

	const saveComputedClusters = useCallback(
		(computedClusters: SignaturesCluster[], distribution: readonly ClusterContributor[], isStaff: boolean) => {
			setSignaturesClusters(computedClusters);
			setHasNoCluster(false);
			if (computedClusters.length === 0 && distribution.length > 0) {
				if (isStaff) {
					setSignaturesClusters(firstZipcode(distribution));
				} else {
					setHasNoCluster(true);
				}
			}
		},
		[setHasNoCluster, setSignaturesClusters],
	);

	return { saveComputedClusters };
}
