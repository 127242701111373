import { useMemo, useState } from 'react';

import type { ClusterContributor, MapConfiguration, SignaturesCluster } from './types';

export type SignaturesDistributionContextValueType = {
	data: MapConfiguration & {
		isLoadingData: boolean;
		isCrunchingData: boolean;
		rerunNow: boolean;
		signaturesClusters: SignaturesCluster[] | undefined;
		hasNoCluster: boolean;
		isNotInSignatureCountRange: boolean;
		signaturesDistribution: readonly ClusterContributor[] | undefined;
		signaturesCount: number | undefined;
		noData: boolean;
		clusterPercentage: number | undefined;
	};
	actions: {
		setIsLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
		setIsCrunchingData: React.Dispatch<React.SetStateAction<boolean>>;
		setZoomLevel: React.Dispatch<React.SetStateAction<number | undefined>>;
		setClusterSize: React.Dispatch<React.SetStateAction<number | undefined>>;
		setRerunNow: React.Dispatch<React.SetStateAction<boolean>>;
		setSignaturesClusters: React.Dispatch<React.SetStateAction<SignaturesCluster[] | undefined>>;
		setHasNoCluster: React.Dispatch<React.SetStateAction<boolean>>;
		setIsNotInSignatureCountRange: React.Dispatch<React.SetStateAction<boolean>>;
		setSignaturesDistribution: React.Dispatch<React.SetStateAction<readonly ClusterContributor[] | undefined>>;
		setSignaturesCount: React.Dispatch<React.SetStateAction<number | undefined>>;
		setNoData: React.Dispatch<React.SetStateAction<boolean>>;
		setClusterPercentage: React.Dispatch<React.SetStateAction<number | undefined>>;
	};
};

// eslint-disable-next-line max-lines-per-function
export const useSignaturesDistributionContextValue = (configuration: MapConfiguration) => {
	const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
	const [isCrunchingData, setIsCrunchingData] = useState<boolean>(false);
	const [zoomLevel, setZoomLevel] = useState<number | undefined>(configuration.zoomLevel);
	const [clusterSize, setClusterSize] = useState<number | undefined>(configuration.clusterSize);
	const [rerunNow, setRerunNow] = useState<boolean>(false);
	const [signaturesClusters, setSignaturesClusters] = useState<SignaturesCluster[] | undefined>(undefined);
	const [hasNoCluster, setHasNoCluster] = useState<boolean>(false);
	const [isNotInSignatureCountRange, setIsNotInSignatureCountRange] = useState<boolean>(false);
	const [signaturesDistribution, setSignaturesDistribution] = useState<readonly ClusterContributor[] | undefined>(
		undefined,
	);
	const [signaturesCount, setSignaturesCount] = useState<number | undefined>(undefined);
	const [noData, setNoData] = useState(false);
	const [clusterPercentage, setClusterPercentage] = useState<number | undefined>(undefined);

	const value = useMemo(
		() => ({
			data: {
				...configuration,
				clusterSize,
				zoomLevel,
				isLoadingData,
				isCrunchingData,
				rerunNow,
				signaturesClusters,
				hasNoCluster,
				isNotInSignatureCountRange,
				signaturesDistribution,
				signaturesCount,
				noData,
				clusterPercentage,
			},
			actions: {
				setIsLoadingData,
				setIsCrunchingData,
				setZoomLevel,
				setClusterSize,
				setRerunNow,
				setSignaturesClusters,
				setHasNoCluster,
				setIsNotInSignatureCountRange,
				setSignaturesDistribution,
				setSignaturesCount,
				setNoData,
				setClusterPercentage,
			},
		}),
		[
			isLoadingData,
			isCrunchingData,
			rerunNow,
			zoomLevel,
			clusterSize,
			configuration,
			signaturesClusters,
			hasNoCluster,
			isNotInSignatureCountRange,
			signaturesDistribution,
			signaturesCount,
			noData,
			clusterPercentage,
		],
	);
	return value;
};
