import { useCallback } from 'react';
import type { JSX } from 'react';

import { Waypoint } from 'react-waypoint';

import type { WaypointCallbackArgs } from 'src/app/shared/components/layout/lazy';

// FIXME move this file to page's shared/hooks folder
// eslint-disable-next-line @change/sibling-module-imports
import { usePageScrollPosition } from '../../hooks/scroll';

export function AfterMobileActionButtonWaypoint(): JSX.Element {
	const { setAfterMobileActionButton } = usePageScrollPosition();

	const onPositionChange = useCallback(
		({ currentPosition }: WaypointCallbackArgs) => setAfterMobileActionButton(currentPosition === 'above'),
		[setAfterMobileActionButton],
	);

	// using onPositionChange so that it is triggered at hydration
	return <Waypoint onPositionChange={onPositionChange} />;
}
