import type { JSX } from 'react';

import { Box } from '@change-corgi/design-system/layout';

import { usePetitionDetails } from '../../../hooks/petitionDetails';
import { PetitionStatusHeaderContainer } from '../../PetitionStatusHeader';

import { SignatureStatsActiveContainer } from './SignatureStatsActive';
import { SignatureStatsClosedContainer } from './SignatureStatsClosed';
import { SignatureStatsVictoryContainer } from './SignatureStatsVictory';

function SignatureStatsContainerInner() {
	const { status } = usePetitionDetails();

	if (status === 'CLOSED') {
		return <SignatureStatsClosedContainer />;
	}

	if (status === 'VICTORY') {
		return <SignatureStatsVictoryContainer />;
	}

	return <SignatureStatsActiveContainer />;
}

export function SignatureStatsContainer(): JSX.Element {
	return (
		<Box mb={16}>
			<PetitionStatusHeaderContainer />
			<SignatureStatsContainerInner />
		</Box>
	);
}
