import { useCallback } from 'react';
import type { JSX } from 'react';

import { Waypoint } from 'react-waypoint';

import type { WaypointCallbackArgs } from 'src/app/shared/components/layout/lazy';

// FIXME move this file to page's shared/hooks folder
// eslint-disable-next-line @change/sibling-module-imports
import { usePageScrollPosition } from '../../hooks/scroll';

export function BeforeSuggestedPetitionsWaypoint(): JSX.Element {
	const { setBeforeSuggestedPetitions } = usePageScrollPosition();

	const onPositionChange = useCallback(
		({ currentPosition }: WaypointCallbackArgs) => setBeforeSuggestedPetitions(currentPosition === 'below'),
		[setBeforeSuggestedPetitions],
	);

	// using onPositionChange so that it is triggered at hydration
	return <Waypoint onPositionChange={onPositionChange} />;
}
