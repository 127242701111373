import { useState } from 'react';
import type { JSX } from 'react';

import type { PrefetchedDataProps } from '@change-corgi/core/react/prefetch';
import { withPrefetchedData } from '@change-corgi/core/react/prefetch';
import { useKnownAvailableParams } from '@change-corgi/core/react/router';

import type { PetitionHeadlineBanditData } from 'src/app/pages/petition/details/shared/types';
import type { PetitionTabProps } from 'src/app/pages/petition/shared/tabTypes';

import { ShareHeadlineProvider, useDefaultShareHeadline, useRouteGuestSharer } from '../hooks/shareHeadline';

import { getCopylinkBanditVariantText, getWhatsappBanditVariantText } from './api';
import { CommonPetitionDetails } from './CommonPetitionDetails';

type PrefetchedData = Readonly<{
	variant?: PetitionHeadlineBanditData;
}>;

export const PetitionDetailsBanditGuest = withPrefetchedData(
	({ userInfoState, prefetchedData }: PetitionTabProps & PrefetchedDataProps<PrefetchedData>): JSX.Element => {
		// this allows us to conserve the sharer after first render
		const [variant] = useState<PetitionHeadlineBanditData | undefined>(prefetchedData?.variant);
		const { slug } = useKnownAvailableParams<{ slug: string; sharerDisplayName: string; sharerLocation: string }>();

		const { title, description } = useDefaultShareHeadline();
		const sharer = useRouteGuestSharer();

		if (!sharer) {
			return <CommonPetitionDetails slug={slug} userInfoState={userInfoState} />;
		}

		if (!variant) {
			return (
				<ShareHeadlineProvider sharer={sharer} title={title} description={description}>
					<CommonPetitionDetails slug={slug} userInfoState={userInfoState} />
				</ShareHeadlineProvider>
			);
		}

		return (
			<ShareHeadlineProvider sharer={sharer} title={variant.headline} description={variant.description}>
				<CommonPetitionDetails slug={slug} userInfoState={userInfoState} />
			</ShareHeadlineProvider>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchDependencies: [CommonPetitionDetails],
		prefetchData: async (context) => {
			const {
				params: { slug, shareType, experimentVariantName },
			} = context;
			if (!shareType || !['wa', 'cl_'].includes(shareType)) return {};
			if (!experimentVariantName) return {};
			try {
				return {
					variant: await (shareType === 'wa'
						? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							getWhatsappBanditVariantText(slug!, experimentVariantName, context.utilityContext)
						: // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							getCopylinkBanditVariantText(slug!, experimentVariantName, context.utilityContext)),
				};
			} catch (e) {
				return {};
			}
		},
	},
);
