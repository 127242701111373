import { useState } from 'react';

import type { PageScrollPosition } from './types';

export function usePageScrollPositionState(): PageScrollPosition {
	const [info, setInfo] = useState<PageScrollPosition>({
		afterDescription: undefined,
		setAfterDescription: (afterDescription: boolean) => {
			setInfo((currentInfo) =>
				afterDescription === currentInfo.afterDescription ? currentInfo : { ...currentInfo, afterDescription },
			);
		},
		beforeSuggestedPetitions: undefined,
		setBeforeSuggestedPetitions: (beforeSuggestedPetitions: boolean) => {
			setInfo((currentInfo) =>
				beforeSuggestedPetitions === currentInfo.beforeSuggestedPetitions
					? currentInfo
					: { ...currentInfo, beforeSuggestedPetitions },
			);
		},
		afterMedia: undefined,
		setAfterMedia: (afterMedia: boolean) => {
			setInfo((currentInfo) => (afterMedia === currentInfo.afterMedia ? currentInfo : { ...currentInfo, afterMedia }));
		},
		afterMobileActionButton: undefined,
		setAfterMobileActionButton: (afterMobileActionButton: boolean) => {
			setInfo((currentInfo) =>
				afterMobileActionButton === currentInfo.afterMobileActionButton
					? currentInfo
					: { ...currentInfo, afterMobileActionButton },
			);
		},
	});
	return info;
}
