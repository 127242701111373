import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { VisuallyHidden } from '@change-corgi/design-system/a11y';
import { Box } from '@change-corgi/design-system/layout';

import { ShareContainer } from '../share';
import { SignatureStatsActiveContainer } from '../stats/signatureStats';

export function SideSignedNonPromotableContainer(): JSX.Element {
	return (
		<>
			<Box mb={8}>
				<SignatureStatsActiveContainer />
			</Box>
			<VisuallyHidden as="h2">
				<Translate value="fe.components.petition_share.action" />
			</VisuallyHidden>
			<ShareContainer forMobileView={false} />
		</>
	);
}
