import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { usePetitionDetails } from '../../hooks/petitionDetails';

export function MetaHrefLangLink(): JSX.Element | null {
	const { originalLocale, slug } = usePetitionDetails();

	const { resolveUrl } = useUtilityContext();

	return (
		<Helmet>
			<link rel="alternate" hrefLang={originalLocale.localeCode} href={resolveUrl(`/p/${slug}`)} />
		</Helmet>
	);
}
