import { useContext } from 'react';

import { PageScrollPositionContext } from './context';
import type { PageScrollPosition } from './types';

export function usePageScrollPosition(): PageScrollPosition {
	const context = useContext(PageScrollPositionContext);

	if (!context) {
		throw new Error('PageScrollPositionContext was not set');
	}

	return context;
}
