import type { JSX } from 'react';

import { CanonicalUrlContainer } from './CanonicalUrl';
import { MetaContainer } from './Meta';
import { TitleContainer } from './Title';

export function HeadContainer(): JSX.Element {
	return (
		<>
			<TitleContainer />
			<CanonicalUrlContainer />
			<MetaContainer />
		</>
	);
}
