import type { JSX, PropsWithChildren } from 'react';
import { useMemo } from 'react';

import { ShareHeadlineContext } from './context';
import type { ShareHeadline } from './types';

type Props = ShareHeadline;

export function ShareHeadlineProvider({ children, description, title, sharer }: PropsWithChildren<Props>): JSX.Element {
	const text = useMemo(
		() => ({
			description,
			title,
			sharer,
		}),
		[description, title, sharer],
	);

	return <ShareHeadlineContext.Provider value={text}>{children}</ShareHeadlineContext.Provider>;
}
