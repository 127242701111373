import type { ClusterContributor } from '../../../shared/types';

import { distanceBetweenPoints } from './distanceBetweenPoints';

type Used = Record<string, boolean>;

export const circles = (size: number, distribution: readonly ClusterContributor[]) => {
	const spies: Used = {};
	const cs: ClusterContributor[][] = [];
	distribution.forEach((a) => {
		const used = spies[a.postalCode];
		if (!used) {
			spies[a.postalCode] = true;
			const c = [a];
			const around = distribution.filter((b) => {
				const distance = distanceBetweenPoints(b.position, a.position);
				return distance <= size && !spies[b.postalCode];
			});
			around.forEach((b) => {
				spies[b.postalCode] = true;
				c.push(b);
			});
			cs.push(c);
		}
	});
	return cs;
};
