import type { SignaturesCluster } from '../../shared/types';

export function centerParametersBuilder() {
	const centerParameters = (signaturesClusters: SignaturesCluster[], clusterSize: number) => {
		const { center } = signaturesClusters[0];
		return {
			strokeColor: '#FF0000',
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor: '#FF0000',
			fillOpacity: 0.35,
			center: { lat: center.latitude, lng: center.longitude },
			radius: clusterSize * 100000,
		};
	};

	return { centerParameters };
}
