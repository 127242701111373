import type { JSX } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { Box, Container } from '@change-corgi/design-system/layout';
import { useColor } from '@change-corgi/design-system/theme';

import { ScrollContainer } from 'src/app/shared/components/ScrollContainer';

import { useFcm } from '../../hooks/fcm';
import { usePetitionDetails } from '../../hooks/petitionDetails';
import { useUserInfo } from '../../hooks/userInfo';

import { PetitionStrengthCard, TipCard } from './components';
import { useGetPetitionStrengthTips } from './hooks/useGetPetitionStrengthTips';

// This section needs to be left aligned, but extend to the right side edge of the page.
// This uses the containers max-width (hardcoded :( ) to calculate how far it should be from the left side of page
const CONTAINER_PADDING = [0, 'calc((100% - 740px + 16px)/2)', 'calc((100% - 960px + 16px)/2)'];

export function StrengthTips(): JSX.Element | null {
	const { translate } = useI18n();
	const petition = usePetitionDetails();
	const { ownPetition } = useUserInfo();
	const tips = useGetPetitionStrengthTips();
	const { strengthTipsEnabled } = useFcm();
	const background = useColor('primary-greyBackground');

	if (!strengthTipsEnabled) {
		return null;
	}

	if (!ownPetition) {
		return null;
	}

	if (tips.length === 0) {
		return null;
	}

	return (
		<Box backgroundColor={background}>
			<Container pt={24} pb={[14, 24]} variant="small">
				<ScrollContainer
					label={translate('corgi.petition.details.strength_score.title')}
					backgroundColor={background}
					sx={{
						paddingLeft: CONTAINER_PADDING,
						// list padding ensures the content itself extends to the edge of the page
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'& > #scroll-container-list': {
							scrollPaddingInline: [16, 0],
							pl: [16, 0],
							pr: [16, 8],
						},
						width: 'max-content',
						maxWidth: '100%',
					}}
				>
					<PetitionStrengthCard score={petition.score} />
					{tips.map((tip) => {
						return <TipCard key={`tip-card-${tip.type}`} tip={tip} />;
					})}
				</ScrollContainer>
			</Container>
		</Box>
	);
}
