import { useCallback } from 'react';

import { useSignaturesDistributionContext } from '../../../shared/provider';
import type { SignaturesCluster } from '../../../shared/types';
import { clusters } from '../utils/clusters';

import { useFindClusters } from './useFindClusters';
import { useSaveComputedClusters } from './useSaveComputedClusters';

export function useComputeClusters() {
	const {
		data: { clusterSize, minSignaturePercentageToBeACluster, signaturesDistribution, signaturesCount },
		actions: { setClusterSize },
	} = useSignaturesDistributionContext();
	const { findClusters } = useFindClusters();
	const { saveComputedClusters } = useSaveComputedClusters();

	const computeClusters = useCallback(
		(isStaff: boolean) => {
			/* istanbul ignore if */
			if (!signaturesDistribution || !signaturesCount) {
				return;
			}
			let computedClusters: SignaturesCluster[];
			if (clusterSize) {
				computedClusters = clusters({
					signatures: { distribution: signaturesDistribution, count: signaturesCount },
					clusterSize,
					minSignaturePercentageToBeACluster,
				});
			} else {
				const { size, foundClusters } = findClusters(signaturesDistribution, signaturesCount);
				computedClusters = foundClusters;
				setClusterSize(Math.floor(10 * size) / 10);
			}
			saveComputedClusters(computedClusters, signaturesDistribution, isStaff);
		},
		[
			signaturesDistribution,
			signaturesCount,
			clusterSize,
			findClusters,
			minSignaturePercentageToBeACluster,
			saveComputedClusters,
			setClusterSize,
		],
	);

	return { computeClusters };
}
