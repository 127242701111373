import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconOutlinedFlag } from '@change-corgi/design-system/icons';
import { Box } from '@change-corgi/design-system/layout';
import { buttonResetStyles } from '@change-corgi/design-system/reset';
import { Text } from '@change-corgi/design-system/typography';

import { ReportAbuseLink } from 'src/app/shared/components/abuse';

import { usePetitionDetails } from '../hooks/petitionDetails';

export function ReportAbuseContainer(): JSX.Element | null {
	const { id, slug } = usePetitionDetails();

	return (
		<Box pt={24}>
			<ReportAbuseLink type="petition" petitionId={id} petitionSlug={slug}>
				<button type="button" sx={{ ...buttonResetStyles, display: 'flex', alignItems: 'center' }}>
					<Icon icon={iconOutlinedFlag} size="s" color="typography-secondary" mr={8} />
					<Text size="caption" sx={{ textDecoration: 'underline' }} color="typography-secondary">
						<Translate value="fe.components.petition_report_abuse.report_link" />
					</Text>
				</button>
			</ReportAbuseLink>
		</Box>
	);
}
