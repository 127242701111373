import type { JSX, PropsWithChildren } from 'react';

import { Icon } from '@change-corgi/design-system/components/icon';
import type { IconComponent } from '@change-corgi/design-system/icons';
import { Box } from '@change-corgi/design-system/layout';
import { Heading, Text } from '@change-corgi/design-system/typography';

type Props = {
	color: string;
	icon: IconComponent;
};

export function PetitionStatusHeader({ color, icon, children }: PropsWithChildren<Props>): JSX.Element {
	return (
		<Box p={8}>
			<Text as="div" sx={{ display: 'inline-flex', alignItems: 'center' }}>
				<Icon icon={icon} size="xl" color={color} mr={8} />
				<Heading size="h3" as="span" ellipsis>
					{children}
				</Heading>
			</Text>
		</Box>
	);
}
