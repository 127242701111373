import { useI18n } from '@change-corgi/core/react/i18n';

import { usePetitionDetails } from '../../../../hooks/petitionDetails';

export function useTitleWithLocation(): string {
	const { getCountry, translate } = useI18n();
	const { ask, city, country } = usePetitionDetails();

	const countryName = getCountry(country.countryCode)?.name;

	let title = `${translate('fe.pages.petition_show.title')} · ${ask}`;

	if (city && countryName) title += ` - ${city}, ${countryName}`;
	else if (countryName) title += ` - ${countryName}`;

	return title;
}
