import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import { useMetaBreadcrumbSchema } from './hooks/useMetaBreadcrumbSchema';

export function MetaBreadcrumbSchema(): JSX.Element | null {
	const {
		data: { displayed, schemaJson },
	} = useMetaBreadcrumbSchema();

	if (!displayed) return null;

	return (
		<Helmet>
			<script type="application/ld+json">{schemaJson}</script>
		</Helmet>
	);
}
