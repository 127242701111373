import type { JSX } from 'react';

import { MediaHitsWrapper } from './components/MediahitsWrapper';
import { MediaHitsContextProvider } from './shared/provider';

export function MediaHitsContainer(): JSX.Element {
	return (
		<MediaHitsContextProvider>
			<MediaHitsWrapper />
		</MediaHitsContextProvider>
	);
}
