import type { JSX } from 'react';

import { MembershipReminderPopup } from 'src/app/shared/components/membershipReminderPopup';

import { DetailsContainer } from '../containers/Details';

import { useTrackPageView } from './hooks/useTrackPageView';

export function Layout(): JSX.Element {
	useTrackPageView();

	return (
		<>
			<MembershipReminderPopup />
			<DetailsContainer />
		</>
	);
}
