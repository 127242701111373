import { usePetitionDetails } from '../../petitionDetails';

export type TrackingPetitionStatus = 'victory' | 'closed' | 'active';

export function useTrackingPetitionStatus(): TrackingPetitionStatus {
	const { status } = usePetitionDetails();

	switch (status) {
		case 'VICTORY':
			return 'victory';
		case 'CLOSED':
			return 'closed';
		default:
			return 'active';
	}
}
