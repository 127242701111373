import type { JSX } from 'react';

import { useLocation } from 'react-router';

import { TranslatePlural } from '@change-corgi/core/react/i18n';
import { ClientRender } from '@change-corgi/core/react/ssr/render';
import { FakeLink, Link } from '@change-corgi/design-system/components/actions';

type Props = Readonly<{
	otherDecisionMakersCount: number;
}>;

export function OtherDecisionMakersButton({ otherDecisionMakersCount }: Props): JSX.Element {
	const { pathname, search } = useLocation();
	return (
		// using a client render here is necessary to avoid hydration issues caused by server-caching
		// where the cached version of the page might have had different query params
		<ClientRender
			placeholder={
				<FakeLink>
					<TranslatePlural
						value="fe.pages.petition_show.petition_show_byline_content.other_decision_makers_link"
						count={otherDecisionMakersCount}
						replacements={{ num: otherDecisionMakersCount }}
					/>
				</FakeLink>
			}
		>
			<Link to={`${pathname + search}#decision-makers-heading`} forceMode="external">
				<TranslatePlural
					value="fe.pages.petition_show.petition_show_byline_content.other_decision_makers_link"
					count={otherDecisionMakersCount}
					replacements={{ num: otherDecisionMakersCount }}
				/>
			</Link>
		</ClientRender>
	);
}
