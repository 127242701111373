import type { SignaturesCluster } from '../../../shared/types';

type Props = {
	signaturesClusters: SignaturesCluster[];
	signaturesCount: number;
};

export const computeClusterPercentage = ({ signaturesClusters, signaturesCount }: Props) => {
	const { count } = signaturesClusters[0];
	return Math.floor((100 * count) / signaturesCount);
};
