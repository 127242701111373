import type { TipCardConfig } from '../../types';

import { useGetTipActions } from './useGetTipActions';
import { useVerifyStrengthTips } from './useVerifyStrengthTips';

export function useGetPetitionStrengthTips(): TipCardConfig[] {
	const selectedTips = useVerifyStrengthTips();
	const getActions = useGetTipActions();

	const tips = selectedTips.map((tip) => {
		return {
			...tip,
			// dynamic properties
			...getActions(tip.type),
		};
	});

	return tips;
}
