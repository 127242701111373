import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import { useTitleWithLocation } from './hooks/useTitleWithLocation';

export function TitleContainer(): JSX.Element | null {
	const title = useTitleWithLocation();

	return (
		<Helmet>
			<title>{title}</title>
		</Helmet>
	);
}
