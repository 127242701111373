import { useMemo } from 'react';

import { usePetitionDetails } from './petitionDetails';

export function usePetitionPageStateData(): unknown {
	const details = usePetitionDetails();

	return useMemo(() => {
		const {
			id,
			user,
			organization,
			createdAt,
			signatureState: { signatureCount, signatureGoal },
			weeklySignatureCount,
			tagsConnection,
			geoReach,
			relevantLocationLocalizedName,
		} = details;

		return {
			id,
			user: {
				id: user.id,
				displayName: user.displayName,
				photo: user.photo,
			},
			organization: organization
				? {
						id: organization.id,
						name: organization.name,
						photo: organization.photo,
					}
				: undefined,
			createdAt,
			signatureCount: {
				displayed: signatureCount.displayed,
				total: signatureCount.total,
				goal: signatureGoal.displayed,
			},
			weeklySignatureCount,
			tags: tagsConnection ? tagsConnection.nodes : [],
			geoReach,
			relevantLocationLocalizedName,
		};
	}, [details]);
}
