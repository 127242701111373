import { useEffect, useMemo, useState } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import { usePetitionDetailsPageData } from 'src/app/pages/petition/details/pageContext';
import type { SupporterVoicesData } from 'src/app/pages/petition/details/pageContext';
import { useCanPlayWebm } from 'src/app/shared/hooks/causes';

import { useCampaignActionsEnabled } from '../useCampaignActionsEnabled';

import type { SupporterVideosList } from './types';

type Result = ModelHookResult<
	{
		supporterVideos: SupporterVideosList | [];
		currentlyPlaying: string;
		showCreateVideo: boolean;
	},
	{
		setCurrentlyPlaying: (videoUrl: string) => void;
		removeVideoFromState: (videoId?: string) => void;
	}
>;

// eslint-disable-next-line max-lines-per-function
export function useSupporterVideos({
	supporterVoicesData: { videos, openVideoCreationFromPetitionDetails, supporterVoicesEnabled },
}: {
	supporterVoicesData: SupporterVoicesData;
}): Result {
	const [currentlyPlaying, setCurrentlyPlaying] = useState('');

	const {
		data: {
			petition: {
				id: petitionId,
				videoSettingsMetadata: { disableCreation: disableVideoCreation },
			},
		},
	} = usePetitionDetailsPageData();

	const canPlayWebm = useCanPlayWebm();
	const track = useTracking();
	const campaignActionsEnabled = useCampaignActionsEnabled();

	const displayVideos = useMemo(() => {
		const listOfVideos = videos?.getPetitionVideosWithLikes?.petitionVideosWithLikes || [];
		if (canPlayWebm) return listOfVideos;
		// Right now GQL returns all transcoded + unprocessed videos. We want to display transcoded videos for browsers (e.g., iOS) that don't support .webm.
		return listOfVideos.filter(({ videoUrl }) => videoUrl.endsWith('.mp4'));
	}, [canPlayWebm, videos]);

	const [supporterVideos, setSupporterVideos] = useState<SupporterVideosList>(displayVideos);

	useEffect(() => {
		if (displayVideos && displayVideos.length > 0) {
			void track('petition_page_supporters_voice_view', {
				petition_id: petitionId,
				showSupporterVoices: supporterVoicesEnabled,
			});
		} // Making this dependent on petitionId because a bug was experienced on demo/staging where you
		// could switch to another petition and have videos from a previous page showing up.
		// This enforces that we re-call this logic anytime we switch petition pages.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [petitionId]);

	const removeVideoFromState = (videoId?: string) => {
		const remainingVideos = supporterVideos?.filter((v) => v.id !== videoId);
		setSupporterVideos(remainingVideos);
	};

	return {
		data: {
			supporterVideos: supporterVideos || [],
			currentlyPlaying,
			showCreateVideo: !!openVideoCreationFromPetitionDetails && campaignActionsEnabled && !disableVideoCreation,
		},
		actions: {
			setCurrentlyPlaying,
			removeVideoFromState,
		},
	};
}
