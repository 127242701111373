import { useCallback } from 'react';
import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { useTracking } from '@change-corgi/core/react/tracking';
import { ButtonLink } from '@change-corgi/design-system/components/actions';
import { iconAdd } from '@change-corgi/design-system/icons';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { usePetitionDetails } from '../hooks/petitionDetails';

export function AddMediaContainer(): JSX.Element {
	const { slug, id } = usePetitionDetails();
	const track = useTracking();

	const trackMediaPromptClick = useCallback(() => {
		void track<{
			petition_id: `petition_${string}`;
			link_type: 'add_a_photo_button';
			view_context: 'petitions_show';
		}>('media_prompt_clicked', {
			petition_id: `petition_${id}`,
			link_type: 'add_a_photo_button',
			view_context: 'petitions_show',
		});
	}, [track, id]);

	return (
		<Box mt={[16, 0]} mx={[16, 0]}>
			<Box mb={8}>
				<Text sx={{ color: 'primary-changeRed', fontWeight: 'bold' }}>
					<Translate value="fe.pages.petition_show.media_prompt.tip" />
				</Text>{' '}
				<Text>
					<Translate value="fe.pages.petition_show.media_prompt.desc" />
				</Text>
			</Box>
			<Box
				sx={{ borderColor: 'neutral-grey400', borderRadius: 'standard', borderStyle: 'dashed', borderWidth: 2 }}
				p={[32, 40]}
			>
				<Flex sx={{ alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
					<ButtonLink
						aria-labelledby="add-media-label"
						icon={iconAdd}
						mode="icon"
						to={`/p/${slug}/edit#media`}
						onClick={trackMediaPromptClick}
						role="link"
						size="small"
						mb={16}
					>
						<Translate value="fe.pages.petition_show.media_prompt.button_label" />
					</ButtonLink>
					<Text aria-hidden="true" id="add-media-label" sx={{ fontWeight: 'bold' }}>
						<Translate value="fe.pages.petition_show.media_prompt.button_label" />
					</Text>
				</Flex>
			</Box>
		</Box>
	);
}
