import { useMemo } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import { usePetitionDetails } from '../petitionDetails';

type Result = {
	trackClick: () => void;
};

type ClickEventProps = Readonly<{
	clicked_user_id: string;
	source_location: string;
}>;

export function usePetitionPageUserLinkTracking(): Result {
	const {
		user: { id },
	} = usePetitionDetails();

	const track = useTracking();

	return useMemo(
		() => ({
			trackClick: () => {
				void track<ClickEventProps>('petition_page_user_link_click', {
					clicked_user_id: id,
					source_location: 'petition_page',
				});
			},
		}),
		[track, id],
	);
}
