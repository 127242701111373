import type { JSX } from 'react';

import { PetitionStatsContextProvider } from '../hooks/petitionStats';

import { DetailsStateWrapper } from './DetailsStateWrapper';

type Props = Readonly<{
	slug: string;
}>;

export function DetailsWithContext({ slug }: Props): JSX.Element {
	return (
		<PetitionStatsContextProvider slug={slug}>
			<DetailsStateWrapper />
		</PetitionStatsContextProvider>
	);
}
