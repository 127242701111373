import { useFcm } from './fcm';
import { usePetitionDetails } from './petitionDetails';
import { useUserInfo } from './userInfo';

export function usePromoteCtaDisplayed(): boolean {
	const { promotable } = usePetitionDetails();
	const { ownPetition } = useUserInfo();
	const { promotionCtaEnabled } = useFcm();

	return promotable && !ownPetition && promotionCtaEnabled;
}
