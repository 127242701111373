import { useEffect } from 'react';

import { useSignaturesDistributionContext } from '../../../shared/provider';

import { useComputeClusters } from './useComputeClusters';
import { useFindClusters } from './useFindClusters';
import { useSaveComputedClusters } from './useSaveComputedClusters';
import { useShouldNotRunClusterCalculation } from './useShouldNotRunClusterCalculation';

export function useSignaturesDistribution(isStaff: boolean) {
	const {
		data: { clusterSize, minSignaturePercentageToBeACluster, signaturesDistribution, signaturesCount },
		actions: { setIsCrunchingData, setRerunNow, setClusterSize },
	} = useSignaturesDistributionContext();
	const { findClusters } = useFindClusters();
	const { saveComputedClusters } = useSaveComputedClusters();
	const { shouldNotRunClusterCalculation } = useShouldNotRunClusterCalculation();
	const { computeClusters } = useComputeClusters();

	useEffect(() => {
		if (shouldNotRunClusterCalculation()) {
			return;
		}
		setRerunNow(false);
		setIsCrunchingData(true);
		setTimeout(() => {
			computeClusters(isStaff);
			setIsCrunchingData(false);
		}, 0);
	}, [
		signaturesDistribution,
		signaturesCount,
		clusterSize,
		isStaff,
		setRerunNow,
		findClusters,
		minSignaturePercentageToBeACluster,
		saveComputedClusters,
		setClusterSize,
		setIsCrunchingData,
		shouldNotRunClusterCalculation,
		computeClusters,
	]);
}
