import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { VisuallyHidden } from '@change-corgi/design-system/a11y';
import { iconLock } from '@change-corgi/design-system/icons';

import { ShareContainer } from '../share';
import { SignatureStatsClosedContainer } from '../stats/signatureStats';

import { SidePetitionStatus } from './components/SidePetitionStatus';

export function SideClosedContainer(): JSX.Element {
	return (
		<>
			<SidePetitionStatus icon={iconLock} iconColor="neutral-grey600">
				<Translate value="fe.pages.petition_show.status.closed" />
			</SidePetitionStatus>
			<SignatureStatsClosedContainer />
			<VisuallyHidden as="h2">
				<Translate value="fe.components.petition_share.action" />
			</VisuallyHidden>
			<ShareContainer forMobileView={false} />
		</>
	);
}
