import type { JSX } from 'react';

import { BreadcrumbLink, Breadcrumbs } from '@change-corgi/design-system/components/navigation';
import { Container } from '@change-corgi/design-system/layout';

import { useTagBreadcrumbs } from './hooks/useTagBreadcrumbs';

export function TagBreadcrumbs(): JSX.Element | null {
	const {
		data: { ask, displayed, slug, tag },
	} = useTagBreadcrumbs();

	if (!displayed) return null;

	return (
		<Container variant="small" pt={64} px={[16, 0]}>
			<Breadcrumbs>
				<BreadcrumbLink to="/">Change.org</BreadcrumbLink>
				<BreadcrumbLink to={`/t/${tag?.slug}`}>{tag?.name}</BreadcrumbLink>
				<BreadcrumbLink to={`/p/${slug}`}>{ask}</BreadcrumbLink>
			</Breadcrumbs>
		</Container>
	);
}
