import { Translate } from '@change-corgi/core/react/i18n';

import { BannerWrapper } from 'src/app/shared/components/banners/layout';
import { useClearedQueryParamValue } from 'src/app/shared/hooks/location';

export function ExpiredSessionBanner() {
	const expired = useClearedQueryParamValue('expired_session');

	if (!expired) return null;

	return (
		<BannerWrapper py={8}>
			<Translate value="fe.pages.petition_show.expired_session" />
		</BannerWrapper>
	);
}
