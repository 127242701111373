import { useCallback } from 'react';

import { getWindow } from '@change-corgi/core/window';

import { useMediaHitsContext } from '../../../../shared/provider';

export function useValidateLink() {
	const MAX_URL_LENGTH = 255;
	const {
		data: { mediaEmbeds },
	} = useMediaHitsContext();

	// NB: This is only possible now that we are limiting to 5 mediahits.
	// It will be near impossible if we start permitting larger  (upwards of 10) media hits.
	// because media hits are basically comments, and it isn't feasible to check if a specific comment has
	// already been added in the db and thus prevent a user from adding that same comment.
	const alreadyAdded = useCallback(
		(url: string) => {
			return mediaEmbeds.find((media) => media.url === url);
		},
		[mediaEmbeds],
	);

	const validateLink = useCallback(
		(value: string | undefined) => {
			if (!value) return 'Cannot be empty';
			const trimmedUrl = value.trim();
			if (trimmedUrl.includes(' ')) return 'Cannot contain spaces';
			if (!trimmedUrl.startsWith('https://')) return 'Must start with https://';
			if (trimmedUrl.length > MAX_URL_LENGTH) return 'Maximum length is 255 characters';
			if (alreadyAdded(value)) return 'URL already added';
			if (trimmedUrl.includes('change.org')) return 'Cannot add a change.org url';
			if (!getWindow().URL.canParse(trimmedUrl)) return 'Not a valid url';
			return undefined;
		},
		[alreadyAdded],
	);

	return validateLink;
}
