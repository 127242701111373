import { useCallback } from 'react';
import type { JSX } from 'react';

import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';

import { LazyComponent } from 'src/app/shared/components/layout/lazy';

import type { SignFormProps } from './SignForm';
import type { SignFormContainerRefreshWrapperContainer } from './SignFormContainerRefreshWrapper';

async function loadSignForm(): Promise<typeof SignFormContainerRefreshWrapperContainer> {
	return (await import(/* webpackChunkName: "pages/petition_details_sign_form" */ './SignFormContainerRefreshWrapper'))
		.SignFormContainerRefreshWrapperContainer;
}

export function SignFormLoader(props: SignFormProps): JSX.Element {
	const loadComponent = useCallback(loadSignForm, []);

	return <LazyComponent loadComponent={loadComponent} fallback={<Loader size="s" mt={64} mx="auto" />} {...props} />;
}

export const preloadSignForm = loadSignForm;
