import type { MapConfiguration, MaybeUndefinedConfiguration, MaybeUndefinedPetitionConfiguration } from '../types';

export const mapConfiguration = (
	{
		minSignaturePercentageToBeACluster: defaultMinSignaturePercentageToBeACluster,
		clusterSearch,
		signatureCount,
	}: MaybeUndefinedConfiguration,
	petitionConfig: MaybeUndefinedPetitionConfiguration | undefined,
): MapConfiguration => {
	return {
		clusterSize: petitionConfig?.clusterSize,
		zoomLevel: petitionConfig?.zoomLevel,
		minSignaturePercentageToBeACluster:
			petitionConfig?.minSignaturePercentageToBeACluster || defaultMinSignaturePercentageToBeACluster || 0.6,
		clusterSearch: clusterSearch || { minimumSize: 0.1, maximumSize: 0.4, increment: 0.1 },
		signatureCount: signatureCount || { minimum: 100, maximum: 50000 },
	};
};
