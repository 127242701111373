import type { HiddingCriteria } from '../types/hiddingCriteria';

import { isPetitionBlocked } from './isPetitionBlocked';
import { isPetitionInAllowedList } from './isPetitionInAllowedList';
import { isPetitionTooOld } from './isPetitionTooOld';
import { isUserInVariantGroup } from './isUserInVariantGroup';

function shouldHideBecauseSignatureCountOutOfRange(
	isInAllowedPetitionsList: boolean,
	count: number,
	range: { minimum: number; maximum: number },
) {
	return !isInAllowedPetitionsList && (count < range.minimum || count > range.maximum);
}

export const mapShouldBeHidden = ({
	session,
	id,
	activatedPercentage,
	actualSignatureCount,
	signatureCountRange,
	createdAt,
	createdAfter,
	petitions,
	blocklist,
}: HiddingCriteria) => {
	const isBlocked = isPetitionBlocked(id, blocklist);
	const isInAllowedPetitionsList = isPetitionInAllowedList(id, petitions);
	const isTooOld = isPetitionTooOld(createdAt, createdAfter);

	if (shouldHideBecauseSignatureCountOutOfRange(isInAllowedPetitionsList, actualSignatureCount, signatureCountRange)) {
		return { hideMap: true, countNotInRange: true };
	}

	if (!isUserInVariantGroup(session, activatedPercentage)) {
		return { hideMap: true, userNotInVariantGroup: true };
	}

	if (isBlocked) {
		return { hideMap: true, petitionIsBlocked: true };
	}

	if (isTooOld && !isInAllowedPetitionsList) {
		return { hideMap: true, petitionIsTooOld: true };
	}

	return { hideMap: false };
};
