import type { JSX } from 'react';

import { usePetitionDetails } from '../../hooks/petitionDetails';
import { useUserInfo } from '../../hooks/userInfo';
import { RespondContainer } from '../Respond';

import { SideClosedContainer } from './SideClosed';
import { SideSignedContainer } from './SideSigned';
import { SideUnsignedContainer } from './SideUnsigned';
import { SideVictoryContainer } from './SideVictory';

export function SideContainer(): JSX.Element {
	const userInfo = useUserInfo();
	const { status } = usePetitionDetails();

	if (userInfo.decisionMaker) {
		return <RespondContainer />;
	}
	if (status === 'CLOSED') {
		return <SideClosedContainer />;
	}
	if (status === 'VICTORY') {
		return <SideVictoryContainer />;
	}
	// TODO: Show share buttons for victory, closed, starter, non-promotable
	if (userInfo.signed) return <SideSignedContainer />;
	return <SideUnsignedContainer />;
}
