import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { MediaHitsQuery, MediaHitsQueryVariables } from './index.graphql';

const EMPTY_MEDIA_HITS: NonNullable<MediaHitsQuery['mediaHitsPage']> = {
	isLastPage: true,
	numberOfStarterMedia: 0,
	mediaHits: [],
};

export async function fetchMediaHits(petitionId: string, { gql: { fetch } }: UtilityContext) {
	try {
		const { data } = await fetch<NonNullable<MediaHitsQuery>, MediaHitsQueryVariables>({
			query: gql`
				query MediaHits($petitionId: ID!, $pageOffset: Int!, $pageSize: Int!) {
					mediaHitsPage: getMediaHits(petitionId: $petitionId, pageOffset: $pageOffset, pageSize: $pageSize) {
						isLastPage
						numberOfStarterMedia
						mediaHits {
							id
							url
							mediaSource
						}
					}
				}
			`,
			variables: {
				petitionId,
				pageOffset: 0,
				pageSize: 10,
			},
			important: true,
			rejectOnError: true,
		});

		return data?.mediaHitsPage || EMPTY_MEDIA_HITS;
	} catch (error) {
		return EMPTY_MEDIA_HITS;
	}
}
