import { SignaturesDistribution } from './components/SignaturesDistribution';
import { useSignaturesDistributionHidden } from './components/SignaturesDistributionHidden';
import { useShouldTheUserSeeTheMap } from './hooks/useShouldTheUserSeeTheMap';
import { SignaturesDistributionContextProvider } from './shared/provider';

export function SignaturesDistributionContainer(): React.JSX.Element | null {
	const {
		shouldSeeTheMap,
		isStaff,
		configuration,
		userNotInVariantGroup,
		petitionIsTooOld,
		petitionIsBlocked,
		countNotInRange,
	} = useShouldTheUserSeeTheMap();
	useSignaturesDistributionHidden({
		userNotInVariantGroup,
		petitionIsTooOld,
		petitionIsBlocked,
		countNotInRange,
	});
	if (!shouldSeeTheMap) {
		return null;
	}

	return (
		<SignaturesDistributionContextProvider configuration={configuration}>
			<SignaturesDistribution isStaff={isStaff} />
		</SignaturesDistributionContextProvider>
	);
}
