import { useCallback } from 'react';
import type { JSX } from 'react';

import { LazyComponent, ScrollableComponent } from 'src/app/shared/components/layout/lazy';

export function UpdatesAndCommentsLoader(): JSX.Element {
	const loadComponent = useCallback(
		async () =>
			(await import(/* webpackChunkName: "pages/petition_details_below_fold" */ './UpdatesAndComments'))
				.UpdatesAndComments,
		[],
	);

	return (
		<ScrollableComponent topOffset={200} immediateForBots>
			<LazyComponent loadComponent={loadComponent} />
		</ScrollableComponent>
	);
}
