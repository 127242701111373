import type { ClusterContributor } from '../../../shared/types';

import { circles } from './circles';

type Props = {
	signatures: {
		distribution: readonly ClusterContributor[];
		count: number;
	};
	clusterSize: number;
	minSignaturePercentageToBeACluster: number;
};

export const clusters = ({
	signatures: { distribution, count: totalSignatureCount },
	clusterSize,
	minSignaturePercentageToBeACluster,
}: Props) => {
	return circles(clusterSize, distribution)
		.map((g) => {
			return {
				count: g.reduce((total, { count }) => total + count, 0),
				contributors: g,
			};
		})
		.filter((e) => e.count >= minSignaturePercentageToBeACluster * totalSignatureCount)
		.map((e) => ({
			...e,
			center: e.contributors[0].position,
		}));
};
