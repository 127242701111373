import type { JSX } from 'react';

import { usePromoteCtaDisplayed } from '../../hooks/promoteCta';

import { SideSignedNonPromotableContainer } from './SideSignedNonPromotable';
import { SideSignedPromotableContainer } from './SideSignedPromotable';

export function SideSignedContainer(): JSX.Element {
	const ctaDisplayed = usePromoteCtaDisplayed();

	return ctaDisplayed ? <SideSignedPromotableContainer /> : <SideSignedNonPromotableContainer />;
}
