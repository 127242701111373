import type { ClusterContributor } from '../../../shared/types';

export function firstZipcode(signaturesDistribution: readonly ClusterContributor[]) {
	return [
		{
			count: signaturesDistribution[0].count,
			contributors: [
				{
					...signaturesDistribution[0],
				},
			],
			center: signaturesDistribution[0].position,
		},
	];
}
