/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo } from 'react';

import type { BreadcrumbList } from 'schema-dts';

import { usePetitionDetailsPageData } from 'src/app/pages/petition/details/pageContext';
import { jsonLd } from 'src/app/shared/utils/jsonLd';

type Result = ModelHookResult<{
	displayed: boolean;
	schemaJson?: string;
}>;

export function useMetaBreadcrumbSchema(): Result {
	const {
		data: {
			petition: { ask, tagsConnection },
		},
	} = usePetitionDetailsPageData();

	const displayed = !!tagsConnection?.nodes.length;

	const breadcrumbList = useMemo(
		() =>
			jsonLd<BreadcrumbList>({
				'@context': 'https://schema.org',
				'@type': 'BreadcrumbList',
				itemListElement: [
					{
						'@type': 'ListItem',
						position: 1,
						name: 'Change.org',
						item: 'https://www.change.org/',
					},
					{
						'@type': 'ListItem',
						position: 2,
						name: tagsConnection?.nodes[0]?.name,
						item: `https://www.change.org/t/${tagsConnection?.nodes[0]?.slug}`,
					},
					{
						'@type': 'ListItem',
						position: 3,
						name: ask,
					},
				],
			}),
		[ask, tagsConnection?.nodes],
	);

	return {
		data: {
			displayed,
			schemaJson: displayed ? breadcrumbList : undefined,
		},
	};
}
