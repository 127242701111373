import { useCallback } from 'react';

import qs from 'qs';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useQueryParamValue } from 'src/app/shared/hooks/location';
import { useCurrentUserIdAsync, useSessionUuidAsync } from 'src/app/shared/hooks/session';
import type { ShareAction, ShareChannel } from 'src/app/shared/hooks/share';
import { useAvailableShareActionsAsync } from 'src/app/shared/hooks/share';
import { isLoaded } from 'src/app/shared/utils/async';

import { usePetitionDetails } from '../../../../../hooks/petitionDetails';

type UseQRCodeShareReturn = ShareAction | null;
export function useQRCodeShare(): UseQRCodeShareReturn {
	const petitionDetails = usePetitionDetails();
	const { resolveUrl } = useUtilityContext();
	const sourceLocation = useQueryParamValue('source_location');
	const sessionState = useSessionUuidAsync();
	const sessionUuid = isLoaded(sessionState) ? sessionState.value : undefined;
	const currentUserState = useCurrentUserIdAsync();
	const currentUserId = isLoaded(currentUserState) ? currentUserState.value : undefined;

	const getText = (_channel: ShareChannel) => '';

	const getUrl = useCallback(
		(_channel: ShareChannel) => {
			const utmParams = qs.stringify(
				{
					utm_source: 'share_petition',
					utm_medium: 'qr_code',
					utm_campaign: 'petition_details',
					recruited_by_id: sessionUuid,
					recruiter: currentUserId,
				},
				{ encodeValuesOnly: true },
			);
			return resolveUrl(`/p/${petitionDetails.slug}?${utmParams}`);
		},
		[currentUserId, petitionDetails.slug, resolveUrl, sessionUuid],
	);

	const getTrackingData = useCallback(
		(_channel: ShareChannel) => ({
			location: 'qr_code_download',
			view_context: 'petition_details',
			own_petition: petitionDetails.user.id === currentUserId,
			context: 'petition_details',
			petition_id: `petition_${petitionDetails.id}`,
			source_location: sourceLocation,
		}),
		[currentUserId, petitionDetails.id, petitionDetails.user.id, sourceLocation],
	);

	const trackingEventName = 'share_petition';
	const channels = ['qrcode'] as const;

	const options = { trackingEventName, getUrl, getTrackingData, getText };
	const actionsState = useAvailableShareActionsAsync(channels, options);
	const shareAction = isLoaded(actionsState) ? actionsState.actions[0] : null;

	return shareAction;
}
