import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { ButtonLink } from '@change-corgi/design-system/components/actions';

import { useNextStepButtonAction } from '../../hooks/useNextStepButtonAction';

import { ActionButtonWrapper } from './ActionButtonWrapper';

type Props = {
	sticky: boolean;
	visible: boolean;
};

export function MobileComboButtonContainer({ sticky, visible }: Props): JSX.Element {
	const {
		data: { comboLink },
		action: { handleNextStepClick },
	} = useNextStepButtonAction();
	return (
		<ActionButtonWrapper visible={visible} sticky={sticky}>
			<ButtonLink
				sx={{ width: '100%' }}
				to={comboLink}
				data-testid="signed-petition-combo-cta"
				onClick={handleNextStepClick}
			>
				<Translate value="fe.pages.petition_update.combo_action_button_label" />
			</ButtonLink>
		</ActionButtonWrapper>
	);
}
