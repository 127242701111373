import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconPauseCircleFilled, iconPlayCircleFilled } from '@change-corgi/design-system/icons';

import { isIPhone } from 'src/app/shared/utils/userAgent';

export function PlayPauseIcons({ isPlaying }: { isPlaying: boolean }): React.JSX.Element {
	const utilityContext = useUtilityContext();
	const iPhone = isIPhone(utilityContext);
	const icon = !iPhone && isPlaying ? iconPauseCircleFilled : iconPlayCircleFilled;

	return (
		<Icon
			icon={icon}
			size="xxxl"
			sx={{
				background: 'radial-gradient(circle at center, white 30%, transparent 50%)',
				opacity: 0.7,
				position: 'absolute',
				bottom: '10px',
				zIndex: 1,
				pointerEvents: 'none',
			}}
			data-testid={icon === iconPlayCircleFilled ? 'playIcon' : 'pauseIcon'}
		/>
	);
}
