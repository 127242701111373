import { useMemo } from 'react';

import qs from 'qs';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { usePetitionDetails } from '../petitionDetails';

const DM_RESPONSE_REQUEST_RECIPIENT = 'dm@change.org';

export function useResponseRequestUrl(): string {
	const { translate } = useI18n();
	const { ask, slug } = usePetitionDetails();
	const { resolveUrl } = useUtilityContext();

	return useMemo(() => {
		const subject = translate('fe.components.petition_decision_maker_response_request.email_subject', {
			petition_title: ask,
		});

		const body = translate('fe.components.petition_decision_maker_response_request.email_body', {
			petition_title: ask,
			petition_url: resolveUrl(`/p/${slug}`),
		});

		return `mailto:${DM_RESPONSE_REQUEST_RECIPIENT}${qs.stringify({ subject, body }, { addQueryPrefix: true })}`;
	}, [translate, ask, slug, resolveUrl]);
}
