import type { Session } from '@change-corgi/core/session';

import { isGuestInVariantGroup } from './isGuestInVariantGroup';
import { isNonGuestInVariantGroup } from './isNonGuestInVariantGroup';
import { isSessionStaff } from './isSessionStaff';

export function isUserInVariantGroup(session: Session, percentage: number) {
	const threshold = percentage / 10;

	return (
		isGuestInVariantGroup(session, threshold) || isNonGuestInVariantGroup(session, threshold) || isSessionStaff(session)
	);
}
