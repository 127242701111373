import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { getPetitionUpdates } from 'src/app/pages/petition/details/shared/api';
import type { PetitionContentUpdate, PetitionUpdates } from 'src/app/pages/petition/details/shared/types';

export async function getDecisionMakerResponses(
	petitionId: string,
	numDecisionMakerResponses: number,
	utilityContext: UtilityContext,
): Promise<PetitionUpdates<PetitionContentUpdate> | undefined> {
	return getPetitionUpdates<PetitionContentUpdate>(
		petitionId,
		{ count: numDecisionMakerResponses, types: ['DM_RESPONSE'] },
		utilityContext,
	);
}
