import { useEffect } from 'react';

import { useSignaturesDistributionContext } from '../../../shared/provider';
import { pickZoomLevel } from '../utils/pickZoomLevel';

export function useZoomLevel() {
	const {
		data: { clusterSize, zoomLevel },
		actions: { setZoomLevel },
	} = useSignaturesDistributionContext();

	useEffect(() => {
		if (clusterSize && !zoomLevel) {
			setZoomLevel(pickZoomLevel(clusterSize));
		}
	}, [clusterSize, zoomLevel, setZoomLevel]);
}
