import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { Link } from '@change-corgi/core/react/router';
import { Button } from '@change-corgi/design-system/components/actions';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { linkResetStyles } from '@change-corgi/design-system/reset';
import { Heading, Text } from '@change-corgi/design-system/typography';

type Props = {
	onClick: () => void;
};

export function SapCta({ onClick }: Props): JSX.Element | null {
	return (
		<Link
			to="/start-a-petition?source_location=petition_show"
			onClick={onClick}
			sx={{ ...linkResetStyles }}
			aria-labelledby="sap_cta_label"
			aria-describedby="sap_cta_desc"
		>
			<Flex
				variant="bordered"
				padding={16}
				sx={{
					borderColor: 'neutral-grey100',
					alignItems: 'center',
					flexDirection: ['column', 'row'],
				}}
			>
				<Box sx={{ flexBasis: '60%' }}>
					<Heading as="div" size="h4" id="sap_cta_label" mb={16} sx={{ textAlign: 'inherit' }}>
						<Translate value="fe.pages.petition_show.sap.start_your_own" />
					</Heading>
					<Text as="div" id="sap_cta_desc" sx={{ textAlign: 'inherit' }}>
						<Translate value="fe.pages.petition_show.sap.cta_description" />
					</Text>
				</Box>
				<Box sx={{ flexBasis: '40%', width: '100%' }}>
					{/* disable tabindex because is not a functioning button - the whole BorderBox is a link */}
					<Button size="medium" variant="secondary" tabIndex={-1} sx={{ width: '100%' }}>
						<Translate value="fe.pages.home.start_a_petition" />
					</Button>
				</Box>
			</Flex>
		</Link>
	);
}
