import type { JSX } from 'react';

/**
 * This should ultimately be replaced fully by the page context providers
 */

import { UnderReviewError } from 'src/shared/error';

import { usePetitionDetailsPageData, usePetitionDetailsPageUserInfo } from 'src/app/pages/petition/details/pageContext';

import { Layout } from '../layout';

export function DetailsStateWrapper(): JSX.Element {
	const {
		data: { petition },
	} = usePetitionDetailsPageData();
	const { canEdit } = usePetitionDetailsPageUserInfo();

	// FIXME should this be moved to pageData/api.ts?
	const underReview = petition.status === 'UNDER_REVIEW';
	if (underReview && !canEdit) {
		// TODO could be moved to the API function during page refactoring
		throw new UnderReviewError();
	}

	return <Layout />;
}
