import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { usePetitionDetailsPageUserData } from 'src/app/pages/petition/details/pageContext';
import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';

import { usePetitionDetails } from '../../../../../../hooks/petitionDetails';
import type { SupporterVideo } from '../../../../types';

type Result = ModelHookResult<
	{
		isPlaying: boolean;
		videoRef: React.RefObject<HTMLVideoElement>;
		petitionId: string;
		petitionSlug: string;
		isGuest: boolean;
	},
	{
		toggleVideoPlayback: () => Promise<void>;
		trackVideoError: (e: unknown) => void;
		setIsPlaying: (playing: boolean) => void;
	}
>;

type UseVideoComponentProps = {
	video: SupporterVideo;
	currentlyPlaying: string;
	setCurrentlyPlaying: (videoUrl: string) => void;
};

// eslint-disable-next-line max-lines-per-function
export function useVideoComponent({ video, currentlyPlaying, setCurrentlyPlaying }: UseVideoComponentProps): Result {
	const { id: videoId, videoUrl } = video;
	const { track, trackError } = useCampaignTracking();
	const [isPlaying, setIsPlaying] = useState(currentlyPlaying === videoUrl);
	const videoRef = useRef<HTMLVideoElement>(null);

	const {
		data: { isGuest },
	} = usePetitionDetailsPageUserData();
	const { id: petitionId, slug: petitionSlug } = usePetitionDetails();

	useEffect(() => {
		const videoEl = videoRef.current;
		if (!videoEl) {
			return;
		}

		if (currentlyPlaying !== videoUrl && !videoEl.paused) {
			videoEl.pause();
		}
	}, [currentlyPlaying, videoUrl]);

	const videoClickedTrackingData = useMemo(
		() => ({
			petition_id: petitionId,
			petition_video_id: videoId,
			video_url: videoUrl,
		}),
		[petitionId, videoId, videoUrl],
	);

	const trackVideoError = useCallback(
		(error: unknown) => {
			trackError('petition_page_supporters_voice_video_error', error, { params: videoClickedTrackingData });
		},
		[trackError, videoClickedTrackingData],
	);
	const toggleVideoPlayback = useCallback(async () => {
		const videoEl = videoRef.current;
		if (!videoEl) {
			return;
		}

		if (!isPlaying) {
			void track('petition_page_supporters_voice_click_play', videoClickedTrackingData);

			try {
				videoEl.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
				await videoEl.play();
				setIsPlaying(true);
				setCurrentlyPlaying(videoUrl);
			} catch (e) {
				trackVideoError(e);
			}
		} else {
			void track('petition_page_supporters_voice_click_pause', videoClickedTrackingData);
			videoEl.pause();
			setIsPlaying(false);
			setCurrentlyPlaying('');
		}
	}, [isPlaying, setCurrentlyPlaying, track, trackVideoError, videoClickedTrackingData, videoUrl]);

	return {
		data: { isPlaying, videoRef, petitionId, petitionSlug, isGuest },
		actions: {
			toggleVideoPlayback,
			trackVideoError,
			setIsPlaying,
		},
	};
}
