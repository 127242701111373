import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import { useMetaArticleSchema } from './hooks/useMetaArticleSchema';

export function MetaArticleSchema(): JSX.Element | null {
	const {
		data: { schemaJson },
	} = useMetaArticleSchema();

	return (
		<Helmet>
			<script type="application/ld+json">{schemaJson}</script>
		</Helmet>
	);
}
