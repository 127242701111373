import type { JSX } from 'react';

import type { PetitionPhoto } from 'src/app/pages/petition/details/shared/types';
import { ImagePreload } from 'src/app/shared/components/optimization';

import { useUserInfo } from '../../hooks/userInfo';
import { AddMediaContainer } from '../AddMedia';

import { Photo } from './components/Photo';
import { PhotoProcessing } from './components/PhotoProcessing';

type Props = {
	photo?: PetitionPhoto;
};

export function MediaContainer({ photo }: Props): JSX.Element | null {
	const { canEdit } = useUserInfo();

	if (photo) {
		if (photo.processing) {
			return <PhotoProcessing />;
		}
		return (
			<>
				<ImagePreload url={[photo.mobileUrl, photo.url]} fetchPriority="high" />
				<Photo id="petition-photo" url={photo.url} mobileUrl={photo.mobileUrl} />
			</>
		);
	}

	if (canEdit) {
		return <AddMediaContainer />;
	}

	return null;
}
