import { usePetitionDetails } from '../../../../../hooks/petitionDetails';
import { TIPS_CONFIGS } from '../../../constants';
import type { TipCardConfig } from '../../../types';

type PartialTipCardConfig = Omit<TipCardConfig, 'url' | 'onClick'>;

export function useVerifyStrengthTips(): PartialTipCardConfig[] {
	const petition = usePetitionDetails();
	return TIPS_CONFIGS.filter((strengthTip) => strengthTip.verifyFunction(petition));
}
