import { useMemo } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';

type Result = Readonly<{
	title: string;
	description: string;
}>;

export function useDefaultShareHeadline(): Result {
	const { translate } = useI18n();

	const title = useMemo(
		() =>
			translate('fe.components.petition_share.share_text_fb', {
				// these are to avoid triggering missing replacements reporting
				sharer_name: '%{sharer_name}',
				signature_target: '%{signature_target}',
				random_2_digit_number: '%{random_2_digit_number}',
			}),
		[translate],
	);
	// For some reason, we're not using fe.components.petition_share.share_text_fb_description
	const description = '%{petition_name}';

	return useMemo(
		() => ({
			title,
			description,
		}),
		[title, description],
	);
}
