import { useEffect } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import { usePetitionDetails } from '../../../../hooks/petitionDetails';

type MapHiddenReasons = {
	userNotInVariantGroup?: boolean;
	petitionIsTooOld?: boolean | undefined;
	petitionIsBlocked?: boolean | undefined;
	noClusterFound?: boolean;
	countNotInRange?: boolean;
};

export function useSignaturesDistributionHidden({
	userNotInVariantGroup,
	petitionIsTooOld,
	petitionIsBlocked,
	noClusterFound,
	countNotInRange,
}: MapHiddenReasons) {
	const { id } = usePetitionDetails();
	const track = useTracking();

	useEffect(() => {
		let trackedReason = 'unknown';
		if (userNotInVariantGroup) {
			trackedReason = 'user not in activated percentage';
		}
		if (petitionIsTooOld) {
			trackedReason = 'petition is too old';
		}
		if (petitionIsBlocked) {
			trackedReason = 'petition is blocked';
		}
		if (countNotInRange) {
			trackedReason = 'signature count not in expected range';
		}
		if (noClusterFound) {
			trackedReason = 'no cluster found';
		}
		if (trackedReason !== 'unknown') {
			void track('petition_sign_map_hidden', {
				petition_id: id,
				reason: trackedReason,
			});
		}
	}, [userNotInVariantGroup, petitionIsTooOld, petitionIsBlocked, noClusterFound, countNotInRange, id, track]);
}
