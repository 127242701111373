import { isLoading } from 'src/app/shared/utils/async';

import { useAsyncFcm } from '../../../hooks/fcm';
import { usePetitionDetails } from '../../../hooks/petitionDetails';
import { useUserState } from '../../supportersVoices/hooks/userUserState';
import type { MapConfiguration } from '../shared/types';
import { isSessionStaff } from '../shared/utils/isSessionStaff';
import { mapConfiguration } from '../shared/utils/mapConfiguration';
import { mapShouldBeHidden } from '../shared/utils/mapShouldBeHidden';

type ShouldSeeTheMapResult =
	| {
			shouldSeeTheMap: true;
			userNotInVariantGroup?: undefined;
			petitionIsTooOld?: undefined;
			petitionIsBlocked?: undefined;
			countNotInRange?: undefined;
			isStaff: boolean;
			configuration: MapConfiguration;
	  }
	| {
			shouldSeeTheMap: false;
			userNotInVariantGroup?: boolean;
			petitionIsTooOld?: boolean;
			petitionIsBlocked?: boolean;
			countNotInRange?: boolean;
			isStaff?: undefined;
			configuration?: undefined;
	  };

export function useShouldTheUserSeeTheMap(): ShouldSeeTheMapResult {
	const fcm = useAsyncFcm();
	const {
		id,
		createdAt,
		country: { countryCode },
		signatureState: {
			signatureCount: { displayed },
		},
	} = usePetitionDetails();
	const userState = useUserState();

	if (countryCode !== 'US') {
		return { shouldSeeTheMap: false };
	}
	if (isLoading(fcm) || isLoading(userState) || !fcm.values.signaturesDistributionConfig) {
		return { shouldSeeTheMap: false };
	}

	const { activatedPercentage, petitions, createdAfter, blocklist, signatureCount } =
		fcm.values.signaturesDistributionConfig;
	const { session } = userState.value;

	const { hideMap, petitionIsTooOld, petitionIsBlocked, userNotInVariantGroup, countNotInRange } = mapShouldBeHidden({
		session,
		id,
		activatedPercentage,
		actualSignatureCount: displayed,
		signatureCountRange: signatureCount,
		createdAt,
		createdAfter,
		petitions,
		blocklist,
	});
	if (hideMap) {
		return {
			shouldSeeTheMap: false,
			petitionIsTooOld,
			petitionIsBlocked,
			userNotInVariantGroup,
			countNotInRange,
		};
	}

	return {
		shouldSeeTheMap: true,
		isStaff: isSessionStaff(session),
		configuration: mapConfiguration(
			fcm.values.signaturesDistributionConfig,
			petitions.find(({ petitionId }) => petitionId === id),
		),
	};
}
