import { useEffect } from 'react';

import { useSignaturesDistributionContext } from '../../../shared/provider';
import { computeClusterPercentage } from '../utils/computeClusterPercentage';

export function useClusterPercentage() {
	const {
		data: { signaturesClusters, signaturesCount },
		actions: { setClusterPercentage },
	} = useSignaturesDistributionContext();

	useEffect(() => {
		if (signaturesClusters && signaturesClusters.length > 0 && signaturesCount) {
			setClusterPercentage(computeClusterPercentage({ signaturesClusters, signaturesCount }));
		}
	}, [signaturesClusters, signaturesCount, setClusterPercentage]);
}
