import type { ComponentProps, JSX } from 'react';
import { useCallback } from 'react';

import { ClientRender } from '@change-corgi/core/react/ssr/render';
import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';
import { useCurrentBreakpointValue } from '@change-corgi/design-system/responsive';

import { LazyComponent } from 'src/app/shared/components/layout/lazy';

import type { ShareContainer } from './Share';

async function loadShare(): Promise<typeof ShareContainer> {
	return (await import(/* webpackChunkName: "pages/petition_details_share" */ './Share')).ShareContainer;
}

function ShareLoaderInner(props: ComponentProps<typeof ShareContainer>) {
	const loadComponent = useCallback(loadShare, []);

	return <LazyComponent loadComponent={loadComponent} fallback={<Loader size="s" mt={64} mx="auto" />} {...props} />;
}

function ShareLoaderForDesktopView({
	forMobileView,
	...restProps
}: ComponentProps<typeof ShareContainer>): JSX.Element {
	const desktop = useCurrentBreakpointValue([false, true]);

	return desktop ? (
		<ShareLoaderInner forMobileView={forMobileView} {...restProps} />
	) : (
		<Loader size="s" mt={64} mx="auto" />
	);
}

export function ShareLoader({ forMobileView, ...restProps }: ComponentProps<typeof ShareContainer>): JSX.Element {
	if (forMobileView) {
		return <ShareLoaderInner forMobileView={forMobileView} {...restProps} />;
	}

	return (
		<ClientRender placeholder={<Loader size="s" mt={64} mx="auto" />}>
			<ShareLoaderForDesktopView forMobileView={forMobileView} {...restProps} />
		</ClientRender>
	);
}

export const preloadShare = loadShare;
