import { useCallback } from 'react';

import { useSignaturesDistributionContext } from '../../../shared/provider';
import type { ClusterContributor, SignaturesCluster } from '../../../shared/types';
import { clusters } from '../utils/clusters';

export function useFindClusters() {
	const {
		data: { minSignaturePercentageToBeACluster, clusterSearch },
	} = useSignaturesDistributionContext();
	const MAX = 1000;

	const findClusters = useCallback(
		(signaturesDistribution: readonly ClusterContributor[], signaturesCount: number) => {
			let count = 0;
			let size = clusterSearch.minimumSize;
			let computedClusters: SignaturesCluster[] = [];
			while (computedClusters.length === 0 && size <= clusterSearch.maximumSize && count < MAX) {
				computedClusters = clusters({
					signatures: { distribution: signaturesDistribution, count: signaturesCount },
					clusterSize: size,
					minSignaturePercentageToBeACluster,
				});
				size += clusterSearch.increment;
				count += 1;
			}
			return { size: size - clusterSearch.increment, foundClusters: computedClusters };
		},
		[clusterSearch.minimumSize, clusterSearch.increment, clusterSearch.maximumSize, minSignaturePercentageToBeACluster],
	);

	return { findClusters };
}
