import type { JSX } from 'react';

import { Redirect } from '@change-corgi/core/react/router';

import { usePetitionDetails } from '../hooks/petitionDetails';

export function RedirectUnpublished(): JSX.Element | null {
	const { status, slug } = usePetitionDetails();

	if (status === 'UNPUBLISHED') {
		return <Redirect to={`/p/${slug}/preview`} reason="unpublished" />;
	}

	return null;
}
