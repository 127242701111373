import type { Session, SessionUser } from '@change-corgi/core/session';

import {
	useCurrentUserAsync,
	useCurrentUserRoleAsync,
	useIsStarterAsync,
	useSessionAsync,
} from 'src/app/shared/hooks/session';
// eslint-disable-next-line @change/sibling-module-imports
import type { AsyncState } from 'src/app/shared/hooks/session/get/shared/types';
import { isLoading } from 'src/app/shared/utils/async';

type UserState = {
	currentUser: SessionUser | undefined;
	userIsStaff: boolean;
	userIsStarter: boolean;
	session: Session;
};

export function useUserState(): AsyncState<UserState> {
	const session = useSessionAsync();
	const currentUser = useCurrentUserAsync();
	const userIsStaff = useCurrentUserRoleAsync('staff');
	const userIsStarter = useIsStarterAsync();

	const stillLoading = { status: 'loading' } as const;

	if (isLoading(session)) return stillLoading;
	if (isLoading(currentUser)) return stillLoading;
	if (isLoading(userIsStaff)) return stillLoading;
	if (isLoading(userIsStarter)) return stillLoading;

	return {
		status: 'loaded',
		value: {
			session: session.value,
			currentUser: currentUser.value,
			userIsStaff: userIsStaff.value,
			userIsStarter: userIsStarter.value,
		},
	};
}
