import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { DeleteMediaHitMutation, DeleteMediaHitMutationVariables } from './index.graphql';

export async function deleteMediaHit(
	mediaId: string,
	utilityContext: UtilityContext,
): Promise<DeleteMediaHitMutation | null | undefined> {
	const {
		gql: { fetch },
	} = utilityContext;

	const { data } = await fetch<DeleteMediaHitMutation, DeleteMediaHitMutationVariables>({
		query: gql`
			mutation DeleteMediaHit($input: DeleteCommentInput!) {
				deleteComment(input: $input) {
					success
					reason
				}
			}
		`,
		variables: {
			input: {
				commentId: mediaId,
			},
		},
	});
	return data;
}
