import { useCallback } from 'react';

import { useSignaturesDistributionContext } from '../../../shared/provider';

export function useShouldNotRunClusterCalculation() {
	const {
		data: { rerunNow, signaturesDistribution, signaturesCount },
	} = useSignaturesDistributionContext();

	const shouldNotRunClusterCalculation = useCallback(() => {
		if (!signaturesDistribution || !signaturesCount) {
			return true;
		}
		if (!rerunNow) {
			return true;
		}
		return false;
	}, [signaturesDistribution, signaturesCount, rerunNow]);

	return { shouldNotRunClusterCalculation };
}
