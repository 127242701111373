import type { DecisionMaker } from '../../hooks/decisionMakers';

import { LoadedDecisionMakerListItem } from './components/LoadedDecisionMakerListItem';
import type { AllDmResponses } from './hooks/useDecisionMakers';

export function DecisionMakersModalInner({
	decisionMakers,
	allDmResponses,
}: {
	decisionMakers: DecisionMaker[];
	allDmResponses: AllDmResponses;
}) {
	return decisionMakers.map((decisionMaker) => (
		// using role due to https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html
		// eslint-disable-next-line jsx-a11y/no-redundant-roles
		<ul role="list" key={decisionMaker.id}>
			<li>
				<LoadedDecisionMakerListItem
					key={decisionMaker.id}
					decisionMaker={decisionMaker}
					allDmResponses={allDmResponses}
				/>
			</li>
		</ul>
	));
}
