import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { ClientRender } from '@change-corgi/core/react/ssr/render';
import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';
import { Box } from '@change-corgi/design-system/layout';
import { useCurrentBreakpointValue } from '@change-corgi/design-system/responsive';
import { Heading } from '@change-corgi/design-system/typography';

import { SignFormContainer } from '../signForm';
import { SignatureStatsActiveContainer } from '../stats/signatureStats';

function SideUnsignedContainerInner(): JSX.Element {
	const desktop = useCurrentBreakpointValue([false, true]);

	return (
		<>
			<Heading as="h2" size="h3" mt={32} mb={16}>
				<Translate value="fe.components.petition_sign.action" />
			</Heading>
			{desktop ? <SignFormContainer /> : <Loader size="s" mt={64} mx="auto" />}
		</>
	);
}

export function SideUnsignedContainer(): JSX.Element {
	return (
		<>
			<Box mb={16}>
				<SignatureStatsActiveContainer />
			</Box>
			{/* using programmatic media query to allow lazy loading of the sign form in modal on mobile */}
			{/* Spinner is necessary for SSR */}
			<ClientRender placeholder={<Loader size="s" mt={64} mx="auto" />}>
				<SideUnsignedContainerInner />
			</ClientRender>
		</>
	);
}
