import { Translate } from '@change-corgi/core/react/i18n';
import { createModalHook } from '@change-corgi/design-system/modals';

import { usePetitionDetailsPageData } from 'src/app/pages/petition/details/pageContext';

import { RespondContainer } from '../Respond';

import { MobileModalContextDecorator } from './shared/MobileModalContextDecorator';
import type { MobileModalContext } from './shared/types';

export const useMobileRespondModal = createModalHook<MobileModalContext>({
	name: 'MobileRespondModal',
	variant: 'fullPage',
	closeOnBreakpoints: [false, true],
	title: function MobileRespondModalTitle() {
		const {
			data: {
				petition: { user },
			},
		} = usePetitionDetailsPageData();
		return (
			<Translate
				value="fe.components.petition_decision_maker_respond.you_are_decision_maker"
				replacements={{ starter_name: user.firstName }}
			/>
		);
	},
	body: function MobileRespondModalBody() {
		return <RespondContainer hideHeading />;
	},
	decorator: ({ children, options }) => (
		<MobileModalContextDecorator {...options}>{children}</MobileModalContextDecorator>
	),
});
