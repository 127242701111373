import { useCallback } from 'react';

import { usePetitionDetails } from './petitionDetails';
import { usePetitionPageUserLinkTracking } from './tracking/petitionPageUserLink';

type UseUserProfileUrlResult = ModelHookResult<
	{
		isAnonymous: boolean;
		urlLink: string;
	},
	{
		onUserNameLinkClick: () => void;
	}
>;

export function useUserProfileUrl(): UseUserProfileUrlResult {
	const {
		user: { id, slug, anonymous },
	} = usePetitionDetails();

	const { trackClick } = usePetitionPageUserLinkTracking();

	const onUserNameLinkClick = useCallback((): void => {
		trackClick();
	}, [trackClick]);

	const urlLink = `/u/${encodeURIComponent(slug || id)}`;

	return {
		data: {
			isAnonymous: anonymous,
			urlLink,
		},
		actions: {
			onUserNameLinkClick,
		},
	};
}
