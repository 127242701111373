import { useCurrentUserAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { useFcm } from '../../../../../../hooks/fcm/syncHooks';
import { useUserInfo } from '../../../../../../hooks/userInfo';

export const useShowAddMediaHit = () => {
	const fcm = useFcm();
	const { ownPetition } = useUserInfo();

	const currentUser = useCurrentUserAsync();
	const user = isLoaded(currentUser) ? currentUser.value : undefined;
	const isCampaignOrSupportStaff = user?.roles.campaigns || user?.roles.support;

	if (!user) return false;
	if (isCampaignOrSupportStaff) return true;

	if (ownPetition) {
		return Number(user?.id[user.id.length - 1]) < (fcm.petitionsMediaHitsConfig?.starterPercentage || 0) / 10;
	}
	return false;
};
